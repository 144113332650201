import React, { useState } from "react"
import { navigate } from "gatsby"
import {
  useCourseStore,
  getCourseNavigation,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Heading from "../../../../../components/heading"
import Stack from "../../../../../components/stack"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import TextPlaceholder from "../../../../../components/text-placeholder"
import Box from "../../../../../components/box"
import Container from "../../../../../components/container"
import Inline from "../../../../../components/inline"
import Select from "../../../../../components/select"
import Note from "../../../../../components/note"

const Page = () => {
  const { setAnswer } = useCourseStore()
  const [verses, setVerses] = useState(2)
  const [lines, setLines] = useState(4)
  const navigation = getCourseNavigation({ courseId: "jahreszeiten" })

  return (
    <LayoutCourse
      as="form"
      onSubmit={(event) => {
        event.preventDefault()
        setAnswer({
          courseId: "jahreszeiten",
          chapterId: "02-eigenes-gedicht",
          taskId: "form-finden",
          answer: {
            verses,
            lines,
          },
        })
        navigate("/kurse/jahreszeiten/02-eigenes-gedicht/silben")
      }}
      navigation={navigation}
      footer={<CourseFooterNext type="submit" value="submit" />}
    >
      <Seo title="Eine Form finden" />
      <Stack>
        <Stack space={3}>
          <Heading as="h1" level={2}>
            Eine Form finden
          </Heading>
        </Stack>
        <Paragraph>
          In vielen Gedichten steckt Mathematik. Hölderlin hat in seinen
          Gedichten genau festgelegt, wieviele Silben in einer Zeile stehen
          dürfen und dabei mit antiken Mustern gespielt. Willst du beim
          Schreiben auch ein wenig Knobeln? Dann kannst du hier ein Muster für
          dein Gedicht festlegen.
        </Paragraph>
        <Note variant="task">
          Lege hier die Anzahl der Strophen und Zeilen fest.
        </Note>
        <Stack>
          <Heading as="h2" level={5}>
            Strophen und Zeilen
          </Heading>
          <Inline alignY="center">
            Ich möchte{" "}
            <Box width={16}>
              <Select
                id="strophen"
                name="strophen"
                value={verses}
                onChange={(event) => {
                  setVerses(parseInt(event.target.value))
                }}
              >
                {Array(4)
                  .fill()
                  .map((_, i) => (
                    <option key={`option-${i}`} value={i + 1}>
                      {i + 1}
                    </option>
                  ))}
              </Select>
            </Box>{" "}
            Strophen mit jeweils{" "}
            <Box width={16}>
              <Select
                id="zeilen"
                name="zeilen"
                value={lines}
                onChange={(event) => {
                  setLines(parseInt(event.target.value))
                }}
              >
                {Array(6)
                  .fill()
                  .map((_, i) => (
                    <option key={`option-${i}`} value={i + 1}>
                      {i + 1}
                    </option>
                  ))}
              </Select>
            </Box>{" "}
            Zeilen.
          </Inline>
        </Stack>
        <Container maxWidth="sm" center={false}>
          <TextPlaceholder paragraphs={verses} lines={lines} words={1}>
            {({ paragraph, line, lineContent }) => <>{lineContent}</>}
          </TextPlaceholder>
        </Container>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
